<template>
  <YsPagination ref="node" :total="total">
    <template #header>
      <el-input v-model="uname" placeholder="请输入用户姓名查找" clearable />
      <el-select v-model="status" placeholder="请选择订单状态">
        <el-option label="全部订单" value="" />
        <el-option label="待付款" value="0" />
        <el-option label="已支付" value="1" />
        <el-option label="已发货" value="2" />
        <el-option label="已收货" value="3" />
        <el-option label="已取消" value="4" />
      </el-select>
      <el-button type="primary" @click.prevent="query">查询</el-button>
    </template>
    <el-table :data="userlist" height="100%" style="width: 100%;">
      <el-table-column  type="expand" width="40" fixed align="center">
        <template #default="props">
          <div class="order-goods-box">
            <el-table :data="props.row.goods"   border>
              <el-table-column fixed label="商品名字" prop="goodsName" />
              <el-table-column label="购买数量" prop="count" />
              <el-table-column label="单价" prop="price">
                <template v-slot="{row}">
                  {{row.price / 100}}
                </template>
              </el-table-column>
              <el-table-column label="托管数量" prop="stockCount" />
              <el-table-column label="已售数量">
                <template v-slot="{row}">
                  {{row.soldCount || '--'}}
                </template>
              </el-table-column>
              <el-table-column label="已提现数量">
                <template v-slot="{row}">
                  {{row.soldCount || '--'}}
                </template>
              </el-table-column>
            </el-table>
          </div>
      </template>
      </el-table-column>
      <!-- <el-table-column label="序号" type="index" width="60" fixed /> -->
      <el-table-column label="用户名称" prop="uname" fixed width="100">
      </el-table-column>
      <el-table-column label="收货人名称" prop="receiveName" width="100">
      </el-table-column>
      <el-table-column label="收货人电话" prop="receivePhone" width="130">
      </el-table-column>
      <el-table-column label="收货地址" prop="receiveAddress" min-width="400">
      </el-table-column>
      <el-table-column label="付款日期" prop="paymentDate" width="200">
        <template #default="scope">
          <span>{{ scope.row.paymentDate ? dayjs(scope.row.paymentDate).format('YYYY-MM-DD HH:mm:ss') : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收货日期" prop="takeOver" width="200">
        <template #default="scope">
          <span>{{ scope.row.takeOver ? dayjs(scope.row.takeOver).format('YYYY-MM-DD HH:mm:ss') : '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="订单状态" width="100">
        <template #default="scope">
          <el-tag class="ml-2" type="danger" v-if="scope.row.status == 0">
            待付款</el-tag>
          <el-tag class="ml-2" type="success" v-if="scope.row.status == 1">
            已支付</el-tag>
          <el-tag class="ml-2" type="warning" v-if="scope.row.status == 2">
            已发货</el-tag>
          <el-tag class="ml-2" type="primary" v-if="scope.row.status == 3">
            已收货</el-tag>
          <el-tag class="ml-2" type="info" v-if="scope.row.status == 4">
            已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template #default="scope">
          <el-button size="small" type="primary" @click="handleSend(scope.row)" v-if="scope.row.status == 1">
            发货</el-button>
          <el-button size="small" type="danger" @click="handleCancle(scope.row)" v-if="scope.row.status == 0">
            取消订单</el-button>
        </template>
      </el-table-column>
    </el-table>
  </YsPagination>
</template>
  
<script>
import { orderListApi,orderCancleApi,orderSendApi } from '@/api/order.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'

export default {
  data() {
    return {
      dayjs,
      total: 0,
      uname: '',
      status: '',//
      userlist: [],
    }
  },

  methods: {
    query() {
      this.$refs.node.pageNo = 1;
      this.$refs.node.queryFunc()
    },
    async getList(obj) {
      let res = await orderListApi({ ...obj, uname: this.uname, status: this.status })
      if (res.meta.code == 0) {
        this.userlist = res.data.rows
        this.total = res.data.count
      }
    },
    // 发货
    handleSend(row) { 
      ElMessageBox.confirm(
                '你确定要发货么？',
                '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success',
            }
            )
                .then(async () => {
                    let res = await orderSendApi({orderId:row.orderId,status:2})
                    if (res.meta.code == 0) {
                        this.$refs.node.queryFunc()
                        ElMessage({
                            type: 'success',
                            message: '发货成功！',
                        })
                    }
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消发货！',
                    })
                })
    },
    //取消订单
    handleCancle(row) {
      ElMessageBox.confirm(
                '你确定要取消该订单么？',
                '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }
            )
                .then(async () => {
                    let res = await orderCancleApi(row.orderId)
                    if (res.meta.code == 0) {
                        this.$refs.node.queryFunc()
                        ElMessage({
                            type: 'success',
                            message: '取消成功！',
                        })
                    }
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '暂不取消！',
                    })
                })
     },

  },
}
</script>
  
<style lang='less' scoped>
.content-box {
  margin: 20px;
  background-color: #ffffff;
}
.order-goods-box {
  margin: 15px;
  border: solid 1px #ddd;
  padding: 10px;
}
</style>
  