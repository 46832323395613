/**
 * @description 封装订单相关的接口
 */

import Ajax from "@/utils/request.js";
import {ORDER_URL,ORDERCANCEL_URL,ORDERFLOW_URL} from "@/config/user.cfg";




/** 后台管理提现列表接口 */
export function orderListApi(params) {
    return Ajax({
        url: ORDER_URL,
        method: 'GET',
        params
    })
}

/** 取消订单接口 */

export function orderCancleApi(id) {
    return Ajax({
        url: ORDERCANCEL_URL + '/' + id,
        method: 'PUT',
    })
}

/** 发货和收货接口 */

export function orderSendApi(data) {
    return Ajax({
        url: ORDERFLOW_URL,
        method: 'PUT',
        data
    })
}